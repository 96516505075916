// Anything exported from this file is importable by other in-browser modules.
export function publicApiFunction() {}

// let loggedInUserPromise = fetch('...').then(r => {
//     if (r.ok) {
//       return r.json()
//     } else {
//       throw Error(`Error getting user, server responded with HTTP ${r.status}`)
//     }
//   })
// let loggedInUserPromise = '89999'
  export function getLoggedInUser() {
//       var token = document.cookie.
//       console.log(token);
// var base64Url = token.split('.')[1];
//     var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//     var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
//         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//     }).join(''));
//      this.tokendecoded = JSON.parse(jsonPayload);
//      return this.tokendecoded;
let name = "X_Zkal" + "=";
let decodedCookie = decodeURIComponent(document.cookie);
console.log(decodedCookie);
let ca = decodedCookie.split(';');
for(let i = 0; i <ca.length; i++) {
  let c = ca[i];
  while (c.charAt(0) == ' ') {
    c = c.substring(1);
  }
  console.log(c.indexOf(name));
  if (c.indexOf(name) == 0) {
    console.log(c.substring(name.length, c.length))
  }
}
console.log("vv");
  }